import i18next, { i18n } from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';
import { EditorSDK } from '@wix/platform-editor-sdk';

// TODO: Current flow does not provide translations and this project does not have it implemented.
// This will be fixed afterwards we will migrate about to OOI.
const myAccountStaticsBaseUrl = 'https://static.parastorage.com/services/my-account-ooi';
const myAccountVersion = '1.130.0'; // TODO: Bump this version of My Account to get newer translations.

const initI18NextClient = (locale: string): Promise<i18n> => {
  return new Promise((resolve, reject) => {
    i18next.use(i18nextXHRBackend).init(
      {
        lng: locale,
        fallbackLng: 'en',
        keySeparator: '$',
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: `${myAccountStaticsBaseUrl}/${myAccountVersion}/assets/locales/messages_{{lng}}.json`,
          crossDomain: true,
        },
        react: {
          wait: true,
        },
      },
      (err) => (err ? reject(err) : resolve(i18next))
    );
  });
};

export const getTranslationFunction = async (editorSDK: EditorSDK) => {
  const locale = await editorSDK.environment.getLocale();
  const i18nClient = await initI18NextClient(locale);
  return i18nClient.t.bind(i18nClient);
};
