type PromiseOrValue<T> = T | Promise<T>;

type HandleOrFallback = <T>(fn: () => PromiseOrValue<T>, fallbackValue: T) => PromiseOrValue<T>;

export const handleOrFallback: HandleOrFallback = async (fn, fallbackValue) => {
  try {
    const response = await fn();
    return response;
  } catch {
    return fallbackValue;
  }
};
