import { EditorSDK } from '@wix/platform-editor-sdk';

import { Experiments } from './types';
import { aboutAppDefinitionId, memberAccountBMPath } from './constants';
import { manageFieldsEventId } from './manifest';
import { refreshApp } from './api';

interface GFPPClickedEvent {
  detail: { id: string };
}

export function shouldRegisterGfppEventListeners(appDefId: string, experiments: Experiments) {
  return experiments.enabled('specs.membersArea.customProfile') && appDefId === aboutAppDefinitionId;
}

export function registerGfppEventListeners(editorSDK: EditorSDK) {
  editorSDK.addEventListener('widgetGfppClicked', ({ detail }: GFPPClickedEvent) => {
    if (detail.id === manageFieldsEventId) {
      editorSDK.editor
        .openDashboardPanel('', {
          url: `${memberAccountBMPath}?referralInfo=my_account_gfpp_cta`,
          closeOtherPanels: false,
        })
        .then(() => refreshApp(editorSDK));
    }
  });
}
